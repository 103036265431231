import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/halvaMedia"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"
import { FormPKW } from "../components/FormPKW"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefitsRedisign } from "../components/MorBenefitsRedisgn"
import { Partners } from "../components/PartnersNew"
import { BottomCta, RefinSeoBgImg } from "../components/BottomCta"
import { Reviews } from "../components/Reviews"
import { Questions } from "../components/Questions"
import { QUESTIONS_FOR_HALVA_PKW } from "../components/Questions/helpers"
import { NewFooter } from "../components/NewFooter"

import { getPageData } from "../helpers/getPageData"
import { PageData } from "../interfaces/pageProps"
import { useGetFirstURL } from "../hooks"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const subTitlePartners = (
  <>
    Совершайте покупки в&nbsp;магазинах-партнерах и&nbsp;получайте до&nbsp;36&nbsp;месяцев рассрочки
  </>
)

const GIFT_TITLE = (
  <>
    Оформите Халву и получите
    <br />
    <b> месяц&nbsp;подписки&nbsp;в&nbsp;подарок</b>
  </>
)

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  useGetFirstURL()

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint={false} hasCTA redLogoWithSovcombank />
      <Banner orderNum="1" />
      <FourAdvantagesPoints
        withTitle
        indent
        variant="newYearMedia"
        title="Преимущества с Халвой:"
        orderNum="2"
      />
      <FormPKW
        progressBar
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="3"
        onlyPKWPage
        isGreenApprove
      />
      <HowInstallmentWorks
        headTitle="Как оформить карту рассрочки «Халва»"
        variant="media"
        orderNum="4"
      />
      <NewCalculator additionalEventInDataLayer orderNum="5" />
      <MoreBenefitsRedisign
        additionalEventInDataLayer
        orderNum="6"
        hasSubtitle
        bottomBlock={false}
      />
      <Partners
        title="Более 260&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="7"
      />
      <BottomCta variant="pkwRedesign" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="8" />
      <Reviews additionalEventInDataLayer orderNum="9" />
      <Questions questionList={QUESTIONS_FOR_HALVA_PKW} orderNum="10" />
      <NewFooter ligal={ligal} orderNum="11" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/halva-media/") {
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
